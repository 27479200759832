<template>
  <div>
    <div class="pr-2" v-if="$router.currentRoute.name !== 'passengers'">
      <v-breadcrumbs :items="breadcrumbs">
        <template v-slot:divider>
          <v-icon>chevron_right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <div v-if="$router.currentRoute.name === 'passengers'">
      <br />
      <h1 class="text-xs-center">Passengers</h1>
      <div>
        <v-btn flat class="d-inline-block" :to="{ name: 'passengers-bulk-notifications' }"><v-icon left>fal fa-mail-bulk</v-icon> <span style="text-decoration: underline">Bulk Notifications</span></v-btn>
      </div>
      <v-layout row wrap>
        <v-flex md6 sm4 pa-2>
          <form @submit.prevent="() => searchPassengers(selectedPassengerTab, 1)">
            <v-text-field
                solo
                v-model="searchKeywords"
                append-icon="fal fa-search"
                :rules="[v => !v || v.length >= 2 || 'Min 2 characters']"
                :disabled="apiInProgress"
                type="search"
                placeholder="Search phone or beacon/tag ID"
                @click:append="() => searchPassengers(selectedPassengerTab, 1)"
            />
          </form>
        </v-flex>
        <v-flex md3 sm4 pa-2>
          <v-select
              clearable
              :items="deviceTypes"
              label="Last Access Type"
              :value="selectedDeviceType"
              @change="onFilterByDeviceType"
          />
        </v-flex>
        <v-flex md3 sm4 pa-2>
          <v-select
              clearable
              :items="countries"
              label="Country"
              :value="selectedCountry ? selectedCountry.countryId : null"
              @change="onFilterByCountry"
          />
        </v-flex>
      </v-layout>
      <v-tabs icons-and-text @change="onTabChanged">
        <v-tabs-slider color="primary" />
        <v-tab ripple href="#passengers">
          Passengers {{selectedPassengerTab === 'passengers' ? `(${totalPassengers})` : ''}}
          <v-icon>fal fa-user</v-icon>
        </v-tab>
        <v-tab ripple href="#pending-passengers">
          Pending Activation {{selectedPassengerTab === 'pending-passengers' ? `(${totalPendingPassengers})` : ''}}
          <v-icon>fal fa-user-plus</v-icon>
        </v-tab>
        <v-tab ripple href="#pending-transfer-passengers">
          Pending Transfer {{selectedPassengerTab === 'pending-transfer-passengers' ? `(${totalPendingTransferPassengers})` : ''}}
          <v-icon>fal fa-user-friends</v-icon>
        </v-tab>
        <v-tab ripple href="#archived-passengers">
          Archived {{selectedPassengerTab === 'archived-passengers' ? `(${totalArchivedPassengers})` : ''}}
          <v-icon>fal fa-user-times</v-icon>
        </v-tab>
        <v-tabs-items v-model="selectedPassengerTab">
          <v-tab-item value="passengers">
            <v-data-table
                v-if="selectedPassengerTab === 'passengers'"
                :headers="headers"
                :items="passengers"
                :pagination.sync="pagination"
                :total-items="getTotal(selectedPassengerTab)"
                :loading="apiInProgress"
                :rows-per-page-items="[10, 25, 50]"
                @update:pagination="onPagination"
                class="elevation-1"
                must-sort
            >
              <template v-slot:items="props">
                <tr @click="loadPassenger(props.item.id)">
                  <td class="pointer">
                    {{ props.item.passengerIdentifier && props.item.passengerIdentifier.phoneNumber }}
                    <div v-if="props.item.passengerAlternateIdentifiers && props.item.passengerAlternateIdentifiers.length">
                      <v-chip small title="Beacon/tag">
                        <v-icon small>far fa-signal-stream</v-icon> &nbsp; <small>{{props.item.passengerAlternateIdentifiers[0].identifierKey}}</small>
                        <span v-if="props.item.passengerAlternateIdentifiers.length > 1"> + {{props.item.passengerAlternateIdentifiers.length - 1}}</span>
                      </v-chip>
                    </div>
                  </td>
                  <td class="pointer">{{ props.item.balanceLabel }}</td>
                  <td class="pointer small">{{ props.item.lastAccessType }}</td>
                  <td class="pointer" :title="props.item.lastKnownTripLabel">
                    <v-text-field
                        class="text-field--flat"
                        readonly
                        v-model="props.item.lastKnownTripLabel"
                        hide-details
                    />
                  </td>
                  <td class="pointer">{{ props.item.dateCreatedLabel }}</td>
                  <td class="pointer">{{ props.item.dateUpdatedLabel }}</td>
                  <td class="justify-center layout px-0">
                    <v-btn
                      flat
                      icon
                      title="Archive/disable profile"
                      :disabled="!hasUserType('admin')"
                      @click.stop="loadArchivePassenger(props.item)">
                      <v-icon small>
                        fas fa-archive
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item value="pending-passengers">
            <v-data-table
                v-if="selectedPassengerTab === 'pending-passengers'"
                :headers="pendingHeaders"
                :items="passengers"
                :pagination.sync="pagination"
                :total-items="getTotal(selectedPassengerTab)"
                :loading="apiInProgress"
                :rows-per-page-items="[10, 25, 50]"
                @update:pagination="onPagination"
                class="elevation-1"
                must-sort
            >
              <template v-slot:items="props">
                <tr @click="loadPassenger(props.item.id)" :class="props.item.isExpired ? 'invited-user--expired' : ''" >
                  <td class="pointer">{{ props.item.passengerIdentifier && props.item.passengerIdentifier.phoneNumber }}</td>
                  <td class="pointer">{{ props.item.lastAccessType }}</td>
                  <td class="pointer">{{ props.item.dateCreatedLabel }}</td>
                  <td class="pointer">
                    <div>{{ props.item.activationCodeExpiryLabel }}</div>
                    <div v-if="props.item.isExpired"><small class="error--text">(Expired)</small></div>
                  </td>
                  <td class="justify-center layout px-0">
  <!--                  <v-btn flat icon @click.stop="resendPendingVerification(props.item)" :disabled="!props.item.isEditable" title="Re-send verification">-->
  <!--                    <v-icon small>-->
  <!--                      fas fa-envelope-->
  <!--                    </v-icon>-->
  <!--                  </v-btn>-->
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item value="pending-transfer-passengers">
            <v-data-table
                v-if="selectedPassengerTab === 'pending-transfer-passengers'"
                :headers="pendingTransferHeaders"
                :items="passengers"
                :pagination.sync="pagination"
                :total-items="getTotal(selectedPassengerTab)"
                :loading="apiInProgress"
                :rows-per-page-items="[10, 25, 50]"
                @update:pagination="onPagination"
                class="elevation-1"
                must-sort
            >
              <template v-slot:items="props">
                <tr @click="loadPassenger(props.item.id)" :class="props.item.isExpired ? 'invited-user--expired' : ''" >
                  <td class="pointer">{{ props.item.passengerIdentifier && props.item.passengerIdentifier.phoneNumber }}</td>
                  <td class="pointer">{{ props.item.lastAccessType }}</td>
                  <td class="pointer">
                    <div>{{ props.item.transferCodeExpiryLabel }}</div>
                    <div v-if="props.item.isExpired"><small class="error--text">(Expired)</small></div>
                  </td>
                  <td class="pointer">{{ props.item.dateUpdatedLabel }}</td>
                  <td class="justify-center layout px-0">
  <!--                  <v-btn flat icon @click.stop="resendPendingTransferVerification(props.item)" title="Re-send transfer invitation">-->
  <!--                    <v-icon small>-->
  <!--                      fas fa-envelope-->
  <!--                    </v-icon>-->
  <!--                  </v-btn>-->
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item value="archived-passengers" v-if="hasUserType('admin')">
            <v-data-table
                v-if="selectedPassengerTab === 'archived-passengers'"
                :headers="archivedHeaders"
                :items="passengers"
                :pagination.sync="pagination"
                :total-items="getTotal(selectedPassengerTab)"
                :loading="apiInProgress"
                :rows-per-page-items="[10, 25, 50]"
                @update:pagination="onPagination"
                class="elevation-1"
                must-sort
            >
              <template v-slot:items="props">
                <tr @click="loadPassenger(props.item.id)">
                  <td class="pointer">{{ props.item.passengerIdentifier && props.item.passengerIdentifier.phoneNumber }}</td>
                  <td class="pointer">{{ props.item.balanceLabel }}</td>
                  <td class="pointer">{{ props.item.lastAccessType }}</td>
                  <td class="pointer" :title="props.item.lastKnownTripLabel">
                    <v-text-field
                        class="text-field--flat"
                        readonly
                        v-model="props.item.lastKnownTripLabel"
                        hide-details
                    />
                  </td>
                  <td class="pointer">{{ props.item.dateCreatedLabel }}</td>
                  <td class="pointer">{{ props.item.dateUpdatedLabel }}</td>
                  <td class="pointer">{{ props.item.dateArchivedLabel }}</td>
                  <td class="justify-center layout px-0">
                    <v-btn
                      flat
                      icon
                      :class="!props.item.passengerIdentifier || !props.item.passengerIdentifier.phoneNumber ? 'text--muted' : ''"
                      :disabled="!hasUserType('admin')"
                      @click.stop="loadUnarchivePassenger(props.item)">
                      <v-icon small>
                        fas fa-undo
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
    <router-view
      :force-root-view-refresh="forceRootViewRefresh"
      :signed-in-user="signedInUser"
      :set-passenger="setPassenger"
      :countries="countries"
      :device-types="deviceTypes"
      :has-user-type="hasUserType">
    </router-view>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
